import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = BoxProps & {
  children: ReactNode;
  columns?: number;
  link?: string;
  title: string;
};

export function Section({ title, link, children, columns = 3 }: Props) {
  const color = useColorModeValue('sand', 'gold');

  return (
    <Box as="section" mb="4rem">
      <Heading
        fontWeight="black"
        fontSize={['2.5rem', '3rem']}
        lineHeight={1}
        mb="2rem"
      >
        {title}
      </Heading>

      <SimpleGrid columns={[1, columns]} gap={6}>
        {children}
      </SimpleGrid>

      {link && (
        <Flex justifyContent="center" fontSize="2xl">
          <Link opacity={0.5} _hover={{ color, opacity: 1 }}>
            See more...
          </Link>
        </Flex>
      )}
    </Box>
  );
}
