import { Box } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { Disqus } from 'gatsby-plugin-disqus';
import React from 'react';
import { useTranslation } from '../contexts/LanguageContext';
import { Section } from './Section';

type Props = {
  id: string;
  title: string;
};

export default function Comments({ title, id }: Props) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const { t } = useTranslation();
  const path = typeof window !== 'undefined' ? window.location.pathname : '';
  const disqusConfig = {
    identifier: id,
    title: title,
    url: `${data.site.siteMetadata.siteUrl + path}`,
  };

  return (
    <Section title={t('comments')} columns={1}>
      <Box maxW="80vw" w="50rem" placeSelf="center">
        <Disqus config={disqusConfig} />
      </Box>
    </Section>
  );
}
