import { Heading, Link, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from '../contexts/LanguageContext';
import { Content } from './Content';

export default function Support() {
  const { t } = useTranslation();
  const buttonBg = useColorModeValue('sand', 'gold');
  const boxBg = useColorModeValue('gray.300', 'gray.800');

  return (
    <Content
      border="2px dashed"
      borderColor={boxBg}
      borderRadius="lg"
      p={['3rem 2rem', '4rem 3rem']}
      textAlign="center"
    >
      <Heading m={0}>{t('support')}</Heading>
      <Text as="blockquote" fontSize={['xl', '2xl']} my="2rem">
        {t('supportphrase')}
      </Text>
      <Link
        _hover={{ textDecor: 'none', filter: 'brightness(1.1)' }}
        bg={buttonBg}
        borderRadius="md"
        color="gray.900"
        d="inline-block"
        fontSize="2xl"
        fontWeight="black"
        href="https://www.buymeacoffee.com/elvessousa"
        isExternal
        mt="1rem"
        p="1rem"
      >
        {t('click')}
      </Link>
    </Content>
  );
}
