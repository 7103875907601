import { Flex, Icon, Link, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { SiFacebook, SiTwitter, SiWhatsapp } from 'react-icons/si';

type Props = {
  title: string;
};

export function Share({ title }: Props) {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const bg = useColorModeValue('gray.300', 'gray.800');
  const color = useColorModeValue('gray.900', 'gray.100');
  const blue = useColorModeValue('darkblue', 'blue');
  const cyan = useColorModeValue('teal', 'cyan');

  const links = [
    {
      hover: blue,
      href: `http://www.facebook.com/sharer.php?u=${url}&t=${title}`,
      icon: SiFacebook,
      label: 'Facebook',
    },
    {
      hover: cyan,
      href: `http://twitter.com/share?text=${title} -&url=${url}`,
      icon: SiTwitter,
      label: 'Twitter',
    },
    {
      hover: 'green',
      href: `https://web.whatsapp.com/send?text=${url}&t=${title}`,
      icon: SiWhatsapp,
      label: 'WhatsApp',
    },
  ];

  return (
    <Flex
      alignItems="center"
      as="aside"
      bg={bg}
      borderRadius="md"
      bottom={['initial', '1rem']}
      flexDir={['row', 'column']}
      gap={3}
      h={['initial', '10rem']}
      justifyContent="center"
      p="1rem"
      pos="fixed"
      right="1rem"
      top={['1rem', 'initial']}
      w={['10rem', 'initial']}
      zIndex={1}
    >
      {links.map(({ label, href, icon, hover }) => (
        <Link key={label} href={href} title={label} color={color} isExternal>
          <Icon
            _hover={{ color: hover }}
            aria-label={label}
            as={icon}
            boxSize="1.5rem"
          />
        </Link>
      ))}
    </Flex>
  );
}
