import { graphql } from "gatsby";
import React from "react";
import Comments from "../components/Comments";
import { Content } from "../components/Content";
import Layout from "../components/Layout";
import { Share } from "../components/Share";
import Support from "../components/Support";

type Post = {
  date: string;
  lang: string;
  slug: string;
  thumbnail: string;
  title: string;
};

type ArticleData = {
  data: {
    markdownRemark: {
      frontmatter: Post;
      html: string;
      timeToRead: number;
    };
  };
};

export default function Article({ data }: ArticleData) {
  const { html, frontmatter, timeToRead } = data.markdownRemark;
  const { date, title, thumbnail, lang, slug } = frontmatter;
  const postDate = new Date(date).toLocaleDateString(lang, {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const subtitle = `${postDate} - ${timeToRead} min`;

  return (
    <Layout
      bg={thumbnail}
      subtitle={subtitle}
      thumbnail={thumbnail}
      title={title}
    >
      <Content
        as="article"
        className="article-content"
        dangerouslySetInnerHTML={{ __html: html }}
        my="4rem"
        maxW="45rem"
      />
      <Share title={title} />
      <Support />
      <Comments title={title} id={slug} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        thumbnail
        title
        lang
      }
      timeToRead
    }
  }
`;
